import React from 'react'
import { motion } from 'framer-motion'

const svgVariants = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.67
        }
    }
}

const groupVariants = {
    hidden: {},
    visible: {}
}

const pathVariants = {
    hidden: {
        fill: "rgba(255, 255, 255, 0)",
        pathLength: 0,
    },
    visible: fillVal => ({
        fill: fillVal,
        pathLength: 1,
        transition: {
            duration: 4,
            repeat: Infinity,
            repeatDelay: 1.5,
            repeatType: "reverse",
            ease: "easeInOut"
        }
    })
}

const SvgMobileApp = () => {
    return (
        <motion.svg width="364" height="728" viewBox="0 0 364 728" fill="none" xmlns="http://www.w3.org/2000/svg"
            variants={svgVariants}
            initial="hidden"
            animate="visible"
            exit={{
                opacity: 0, transition: {
                    duration: 0.2
                }
            }}
        >
            <path id="Vector" d="M363.884 172.947H359.885V63.4019C359.885 46.5867 353.205 30.4602 341.315 18.57C329.425 6.67985 313.299 2.12175e-05 296.483 2.01905e-10H64.3966C56.0706 -2.10113e-05 47.8261 1.6399 40.1338 4.82612C32.4415 8.01234 25.4521 12.6825 19.5647 18.5699C13.6773 24.4573 9.00712 31.4466 5.82086 39.1389C2.6346 46.8311 0.994646 55.0757 0.994629 63.4017V664.376C0.99462 672.702 2.63457 680.947 5.8208 688.639C9.00704 696.331 13.6772 703.321 19.5646 709.208C25.452 715.095 32.4413 719.766 40.1336 722.952C47.8259 726.138 56.0704 727.778 64.3965 727.778H296.483C313.298 727.778 329.425 721.098 341.315 709.208C353.205 697.318 359.885 681.192 359.885 664.376V250.923H363.884V172.947Z" fill="#3F3D56" />
            <path id="Vector_2" d="M299.041 16.495H268.746C270.14 19.9106 270.671 23.6165 270.295 27.2861C269.918 30.9557 268.645 34.4764 266.588 37.538C264.53 40.5997 261.751 43.1084 258.496 44.8431C255.24 46.5778 251.608 47.4852 247.919 47.4855H114.96C111.271 47.4852 107.639 46.5778 104.383 44.8431C101.128 43.1084 98.3487 40.5998 96.2911 37.5381C94.2335 34.4764 92.9604 30.9557 92.584 27.2861C92.2076 23.6165 92.7394 19.9106 94.1326 16.495H65.837C53.2796 16.495 41.2365 21.4834 32.357 30.3628C23.4776 39.2422 18.4892 51.2853 18.4891 63.8427V663.935C18.4891 670.153 19.7138 676.31 22.0933 682.055C24.4727 687.799 27.9603 693.019 32.357 697.415C36.7536 701.812 41.9732 705.3 47.7177 707.679C53.4622 710.058 59.6192 711.283 65.837 711.283H299.041C305.259 711.283 311.416 710.058 317.161 707.679C322.905 705.3 328.125 701.812 332.521 697.415C336.918 693.019 340.406 687.799 342.785 682.055C345.165 676.31 346.389 670.153 346.389 663.935V63.8427C346.389 57.6249 345.165 51.468 342.785 45.7235C340.406 39.979 336.918 34.7594 332.521 30.3628C328.125 25.9662 322.905 22.4786 317.161 20.0991C311.416 17.7197 305.259 16.495 299.041 16.495Z" fill="white" />
            <motion.g id="modal1"
                variants={groupVariants}
            >
                <motion.path id="Vector_3" d="M323.82 182.504H40.9462C39.5574 182.502 38.226 181.95 37.2439 180.968C36.2619 179.986 35.7095 178.654 35.7078 177.265V107.096C35.7095 105.708 36.2619 104.376 37.2439 103.394C38.226 102.412 39.5574 101.86 40.9462 101.858H323.82C325.209 101.86 326.54 102.412 327.523 103.394C328.505 104.376 329.057 105.708 329.059 107.096V177.265C329.057 178.654 328.505 179.986 327.522 180.968C326.54 181.95 325.209 182.502 323.82 182.504ZM40.9462 103.953C40.1129 103.954 39.314 104.286 38.7247 104.875C38.1355 105.464 37.8041 106.263 37.8032 107.096V177.265C37.8041 178.099 38.1355 178.898 38.7247 179.487C39.314 180.076 40.1129 180.408 40.9462 180.408H323.82C324.654 180.408 325.452 180.076 326.042 179.487C326.631 178.898 326.962 178.099 326.963 177.265V107.096C326.962 106.263 326.631 105.464 326.042 104.875C325.452 104.286 324.654 103.954 323.82 103.953H40.9462Z"
                    // fill="#3F3D56" 
                    stroke="#3F3D56"
                    variants={pathVariants}
                    custom={"#3F3D56"}
                />
                <motion.path id="Vector_4" d="M84.8991 160.827C96.684 160.827 106.238 151.273 106.238 139.488C106.238 127.704 96.684 118.15 84.8991 118.15C73.1142 118.15 63.5606 127.704 63.5606 139.488C63.5606 151.273 73.1142 160.827 84.8991 160.827Z"
                    // fill="#FF8C00" 
                    stroke="#FF8C00"
                    variants={pathVariants}
                    custom={"#FF8C00"}
                />
                <motion.path id="Vector_5" d="M134.181 125.263C133.239 125.265 132.337 125.641 131.671 126.307C131.006 126.974 130.633 127.877 130.633 128.819C130.633 129.761 131.006 130.664 131.671 131.331C132.337 131.998 133.239 132.373 134.181 132.376H301.84C302.308 132.377 302.771 132.286 303.203 132.109C303.635 131.931 304.028 131.67 304.36 131.341C304.691 131.011 304.954 130.619 305.134 130.188C305.314 129.757 305.407 129.294 305.408 128.827C305.409 128.359 305.318 127.896 305.14 127.464C304.962 127.032 304.7 126.639 304.37 126.308C304.04 125.977 303.648 125.715 303.217 125.535C302.785 125.356 302.322 125.263 301.855 125.263C301.85 125.263 301.845 125.263 301.84 125.263H134.181Z"
                    // fill="#FF8C00" 
                    stroke="#FF8C00"
                    variants={pathVariants}
                    custom={"#FF8C00"}
                />
                <motion.path id="Vector_6" d="M134.181 146.601C133.239 146.604 132.337 146.979 131.671 147.646C131.006 148.313 130.633 149.216 130.633 150.158C130.633 151.099 131.006 152.003 131.671 152.669C132.337 153.336 133.239 153.712 134.181 153.714H206.325C206.792 153.716 207.255 153.625 207.688 153.447C208.12 153.27 208.513 153.009 208.845 152.679C209.176 152.35 209.439 151.958 209.619 151.527C209.799 151.095 209.892 150.633 209.893 150.165C209.894 149.698 209.803 149.235 209.625 148.803C209.447 148.371 209.185 147.978 208.855 147.647C208.525 147.316 208.133 147.053 207.702 146.874C207.27 146.694 206.807 146.602 206.34 146.601C206.335 146.601 206.33 146.601 206.325 146.601H134.181Z"
                    // fill="#FF8C00" 
                    stroke="#FF8C00"
                    variants={pathVariants}
                    custom={"#FF8C00"}
                />
            </motion.g>
            <motion.g id="mountain1"
                variants={groupVariants}
            >
                <motion.path id="Vector_7" d="M166.557 291.475H48.6188L76.3736 243.402C76.871 242.54 77.5865 241.824 78.4481 241.327C79.3097 240.83 80.2871 240.568 81.282 240.568C82.277 240.568 83.2544 240.83 84.116 241.327C84.9776 241.824 85.693 242.54 86.1905 243.402L98.0469 263.938L118.207 229.02C118.83 227.941 119.726 227.045 120.804 226.422C121.883 225.799 123.107 225.471 124.352 225.471C125.598 225.471 126.822 225.799 127.901 226.422C128.979 227.045 129.875 227.941 130.498 229.02L166.557 291.475ZM52.2479 289.38H162.928L128.683 230.067C128.244 229.307 127.613 228.676 126.853 228.237C126.093 227.798 125.23 227.567 124.353 227.567C123.475 227.567 122.613 227.798 121.852 228.237C121.092 228.676 120.461 229.307 120.022 230.067L98.047 268.128L84.3755 244.449C84.0621 243.906 83.6113 243.455 83.0682 243.142C82.5252 242.828 81.9092 242.663 81.2821 242.663C80.6551 242.663 80.0391 242.828 79.4961 243.142C78.953 243.455 78.5021 243.906 78.1887 244.449L52.2479 289.38Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
                <motion.path id="Vector_8" d="M166.668 291.462H40.9463C39.5575 291.461 38.226 290.908 37.244 289.926C36.262 288.944 35.7095 287.613 35.7079 286.224V216.055C35.7095 214.666 36.262 213.335 37.244 212.353C38.226 211.371 39.5575 210.818 40.9463 210.817H166.668C168.057 210.818 169.389 211.371 170.371 212.353C171.353 213.335 171.905 214.666 171.907 216.055V286.224C171.905 287.613 171.353 288.944 170.371 289.926C169.389 290.908 168.057 291.461 166.668 291.462ZM40.9463 212.912C40.1129 212.913 39.314 213.244 38.7248 213.834C38.1355 214.423 37.8041 215.222 37.8032 216.055V286.224C37.8041 287.057 38.1355 287.856 38.7248 288.445C39.314 289.035 40.1129 289.366 40.9463 289.367H166.668C167.502 289.366 168.3 289.035 168.89 288.445C169.479 287.856 169.81 287.057 169.811 286.224V216.055C169.81 215.222 169.479 214.423 168.89 213.833C168.3 213.244 167.502 212.913 166.668 212.912H40.9463Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
            </motion.g>
            <motion.g id="mountain2"
                variants={groupVariants}
            >
                <motion.path id="Vector_9" d="M321.725 291.462H196.003C194.614 291.461 193.283 290.908 192.301 289.926C191.318 288.944 190.766 287.613 190.764 286.224V216.055C190.766 214.666 191.318 213.335 192.301 212.353C193.283 211.371 194.614 210.818 196.003 210.817H321.725C323.114 210.818 324.445 211.371 325.427 212.353C326.409 213.335 326.962 214.666 326.963 216.055V286.224C326.962 287.613 326.409 288.944 325.427 289.926C324.445 290.908 323.114 291.461 321.725 291.462ZM196.003 212.912C195.169 212.913 194.371 213.244 193.781 213.834C193.192 214.423 192.861 215.222 192.86 216.055V286.224C192.861 287.057 193.192 287.856 193.781 288.445C194.371 289.035 195.169 289.366 196.003 289.367H321.725C322.558 289.366 323.357 289.035 323.946 288.445C324.536 287.856 324.867 287.057 324.868 286.224V216.055C324.867 215.222 324.536 214.423 323.946 213.833C323.357 213.244 322.558 212.913 321.725 212.912H196.003Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
                <motion.path id="Vector_10" d="M317.423 291.475H199.485L227.24 243.402C227.737 242.54 228.452 241.824 229.314 241.327C230.176 240.829 231.153 240.568 232.148 240.568C233.143 240.568 234.12 240.829 234.982 241.327C235.843 241.824 236.559 242.54 237.056 243.402L248.913 263.938L269.073 229.02C269.696 227.941 270.592 227.045 271.67 226.422C272.749 225.799 273.973 225.471 275.218 225.471C276.464 225.471 277.688 225.799 278.766 226.422C279.845 227.045 280.741 227.941 281.364 229.02L317.423 291.475ZM203.114 289.38H313.794L279.549 230.067C279.11 229.307 278.479 228.676 277.719 228.237C276.958 227.798 276.096 227.567 275.218 227.567C274.341 227.567 273.478 227.798 272.718 228.237C271.958 228.676 271.327 229.307 270.888 230.067L248.913 268.128L235.241 244.449C234.928 243.906 234.477 243.455 233.934 243.142C233.391 242.828 232.775 242.663 232.148 242.663C231.521 242.663 230.905 242.828 230.362 243.142C229.819 243.455 229.368 243.906 229.055 244.449L203.114 289.38Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
            </motion.g>
            <motion.g id="modal2"
                variants={groupVariants}
            >
                <motion.path id="Vector_11" d="M323.82 460.139H40.9462C39.5574 460.137 38.226 459.585 37.2439 458.603C36.2619 457.621 35.7095 456.289 35.7078 454.9V325.014C35.7095 323.625 36.2619 322.293 37.2439 321.311C38.226 320.329 39.5574 319.777 40.9462 319.775H323.82C325.209 319.777 326.54 320.329 327.522 321.311C328.504 322.293 329.057 323.625 329.059 325.014V454.9C329.057 456.289 328.504 457.621 327.522 458.603C326.54 459.585 325.209 460.137 323.82 460.139ZM40.9462 321.871C40.1129 321.872 39.314 322.203 38.7247 322.792C38.1355 323.381 37.8041 324.18 37.8032 325.014V454.9C37.8041 455.734 38.1355 456.533 38.7247 457.122C39.314 457.711 40.1129 458.043 40.9462 458.043H323.82C324.654 458.043 325.452 457.711 326.042 457.122C326.631 456.533 326.962 455.734 326.963 454.9V325.014C326.962 324.18 326.631 323.381 326.042 322.792C325.452 322.203 324.654 321.872 323.82 321.871H40.9462Z"
                    // fill="#3F3D56" 
                    stroke="#3F3D56"
                    variants={pathVariants}
                    custom={"#3F3D56"}
                />
                <motion.path id="Vector_12" d="M82.8037 387.126C94.5887 387.126 104.142 377.572 104.142 365.787C104.142 354.002 94.5887 344.449 82.8037 344.449C71.0188 344.449 61.4653 354.002 61.4653 365.787C61.4653 377.572 71.0188 387.126 82.8037 387.126Z"
                    // fill="#FF8C00" 
                    stroke="#FF8C00"
                    variants={pathVariants}
                    custom={"#FF8C00"}
                />
                <motion.path id="Vector_13" d="M132.085 351.562C131.142 351.562 130.238 351.936 129.571 352.603C128.904 353.27 128.529 354.175 128.529 355.118C128.529 356.061 128.904 356.966 129.571 357.633C130.238 358.3 131.142 358.674 132.085 358.674H299.745C300.687 358.672 301.589 358.297 302.254 357.63C302.919 356.963 303.293 356.06 303.293 355.118C303.293 354.176 302.919 353.273 302.254 352.606C301.589 351.939 300.687 351.564 299.745 351.562H132.085Z"
                    // fill="#FF8C00" 
                    stroke="#FF8C00"
                    variants={pathVariants}
                    custom={"#FF8C00"}
                />
                <motion.path id="Vector_14" d="M132.085 372.9C131.618 372.899 131.156 372.99 130.724 373.168C130.292 373.346 129.899 373.607 129.568 373.936C129.237 374.266 128.975 374.657 128.795 375.088C128.615 375.52 128.522 375.982 128.521 376.449C128.521 376.916 128.612 377.379 128.789 377.81C128.967 378.242 129.228 378.635 129.558 378.966C129.887 379.297 130.279 379.56 130.71 379.739C131.141 379.919 131.603 380.012 132.07 380.013H204.23C204.697 380.014 205.16 379.922 205.593 379.744C206.025 379.565 206.417 379.304 206.748 378.973C207.079 378.643 207.342 378.251 207.521 377.819C207.7 377.387 207.792 376.924 207.792 376.456C207.792 375.989 207.7 375.526 207.521 375.094C207.342 374.662 207.079 374.27 206.748 373.94C206.417 373.609 206.025 373.347 205.593 373.169C205.16 372.991 204.697 372.899 204.23 372.9H132.085Z"
                    // fill="#FF8C00" 
                    stroke="#FF8C00"
                    variants={pathVariants}
                    custom={"#FF8C00"}
                />
                <motion.path id="Vector_15" d="M66.4366 407.014C63.7313 407.014 61.5298 408.61 61.5298 410.57C61.5298 412.531 63.7313 414.127 66.4366 414.127H297.755C300.46 414.127 302.662 412.531 302.662 410.57C302.662 408.61 300.46 407.014 297.755 407.014H66.4366Z"
                    // fill="#CCCCCC" 
                    stroke="#CCCCCC"
                    variants={pathVariants}
                    custom={"#CCCCCC"}
                />
                <motion.path id="Vector_16" d="M66.4366 428.353C63.7313 428.353 61.5298 429.948 61.5298 431.909C61.5298 433.87 63.7313 435.465 66.4366 435.465H297.755C300.46 435.465 302.662 433.87 302.662 431.909C302.662 429.948 300.46 428.353 297.755 428.353H66.4366Z"
                    // fill="#CCCCCC" 
                    stroke="#CCCCCC"
                    variants={pathVariants}
                    custom={"#CCCCCC"}
                />
            </motion.g>
            <motion.g id="modal3"
                variants={groupVariants}
            >
                <motion.path id="Vector_17" d="M166.668 629.863H40.9463C39.5575 629.861 38.226 629.309 37.244 628.327C36.2619 627.345 35.7095 626.013 35.7079 624.624V493.69C35.7095 492.301 36.262 490.97 37.244 489.988C38.2261 489.006 39.5575 488.453 40.9463 488.452H166.668C168.057 488.453 169.389 489.006 170.371 489.988C171.353 490.97 171.905 492.301 171.907 493.69V624.624C171.905 626.013 171.353 627.345 170.371 628.327C169.389 629.309 168.057 629.861 166.668 629.863ZM40.9463 490.547C40.113 490.548 39.314 490.879 38.7248 491.469C38.1355 492.058 37.8041 492.857 37.8032 493.69V624.624C37.8041 625.458 38.1355 626.257 38.7248 626.846C39.314 627.435 40.113 627.767 40.9463 627.768H166.668C167.502 627.767 168.301 627.435 168.89 626.846C169.479 626.257 169.81 625.458 169.811 624.624V493.69C169.81 492.857 169.479 492.058 168.89 491.469C168.301 490.879 167.502 490.548 166.668 490.547H40.9463Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
                <motion.path id="Vector_18" d="M67.7351 515.453C67.2675 515.452 66.8044 515.543 66.3723 515.722C65.9401 515.9 65.5473 516.162 65.2164 516.492C64.8856 516.823 64.6231 517.215 64.444 517.647C64.2649 518.079 64.1727 518.542 64.1727 519.009C64.1727 519.477 64.2649 519.94 64.444 520.372C64.6231 520.803 64.8856 521.196 65.2164 521.526C65.5473 521.856 65.9401 522.118 66.3723 522.297C66.8044 522.475 67.2675 522.566 67.7351 522.566H139.879C140.823 522.566 141.727 522.191 142.394 521.524C143.061 520.857 143.436 519.952 143.436 519.009C143.436 518.066 143.061 517.161 142.394 516.494C141.727 515.827 140.823 515.453 139.879 515.453H67.7351Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
                <motion.path id="Vector_19" d="M67.7351 536.406C67.2675 536.406 66.8044 536.497 66.3723 536.675C65.9401 536.854 65.5473 537.116 65.2164 537.446C64.8856 537.776 64.6231 538.169 64.444 538.6C64.2649 539.032 64.1727 539.495 64.1727 539.963C64.1727 540.43 64.2649 540.893 64.444 541.325C64.6231 541.757 64.8856 542.149 65.2164 542.48C65.5473 542.81 65.9401 543.072 66.3723 543.25C66.8044 543.429 67.2675 543.52 67.7351 543.519H139.879C140.822 543.518 141.725 543.142 142.39 542.475C143.056 541.809 143.43 540.905 143.43 539.963C143.43 539.021 143.056 538.117 142.39 537.45C141.725 536.783 140.822 536.408 139.879 536.406H67.7351Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
                <motion.path id="Vector_20" d="M103.808 602.862C99.38 602.862 95.0518 601.549 91.3705 599.089C87.6891 596.629 84.8199 593.133 83.1255 589.043C81.4312 584.952 80.9879 580.451 81.8516 576.109C82.7154 571.766 84.8475 567.777 87.9782 564.647C91.109 561.516 95.0978 559.384 99.4402 558.52C103.783 557.656 108.284 558.1 112.374 559.794C116.465 561.488 119.961 564.358 122.421 568.039C124.881 571.72 126.193 576.048 126.193 580.476C126.187 586.411 123.826 592.101 119.629 596.298C115.433 600.494 109.743 602.855 103.808 602.862ZM103.808 560.185C99.7944 560.185 95.8714 561.375 92.5346 563.605C89.1978 565.834 86.5972 569.003 85.0614 572.711C83.5257 576.419 83.1238 580.498 83.9067 584.434C84.6897 588.37 86.6222 591.986 89.4599 594.824C92.2976 597.661 95.913 599.594 99.849 600.377C103.785 601.16 107.865 600.758 111.572 599.222C115.28 597.686 118.449 595.086 120.679 591.749C122.908 588.412 124.098 584.489 124.098 580.476C124.092 575.096 121.952 569.939 118.148 566.135C114.344 562.331 109.187 560.192 103.808 560.185Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
            </motion.g>
            <motion.g id="modal4"
                variants={groupVariants}
            >
                <motion.path id="Vector_21" d="M321.725 629.863H196.003C194.614 629.861 193.283 629.309 192.301 628.327C191.318 627.345 190.766 626.013 190.764 624.624V493.69C190.766 492.301 191.318 490.97 192.301 489.988C193.283 489.006 194.614 488.453 196.003 488.452H321.725C323.114 488.453 324.445 489.006 325.427 489.988C326.409 490.97 326.962 492.301 326.963 493.69V624.624C326.962 626.013 326.409 627.345 325.427 628.327C324.445 629.309 323.114 629.861 321.725 629.863ZM196.003 490.547C195.169 490.548 194.371 490.879 193.781 491.469C193.192 492.058 192.861 492.857 192.86 493.69V624.624C192.861 625.458 193.192 626.257 193.781 626.846C194.371 627.435 195.169 627.767 196.003 627.768H321.725C322.558 627.767 323.357 627.435 323.946 626.846C324.536 626.257 324.867 625.458 324.868 624.624V493.69C324.867 492.857 324.536 492.058 323.946 491.469C323.357 490.879 322.558 490.548 321.725 490.547H196.003Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
                <motion.path id="Vector_22" d="M294.936 602.862C295.403 602.863 295.867 602.771 296.299 602.593C296.731 602.414 297.124 602.153 297.455 601.822C297.785 601.492 298.048 601.1 298.227 600.668C298.406 600.236 298.498 599.773 298.498 599.305C298.498 598.838 298.406 598.375 298.227 597.943C298.048 597.511 297.785 597.119 297.455 596.789C297.124 596.458 296.731 596.196 296.299 596.018C295.867 595.84 295.403 595.748 294.936 595.749H222.792C221.848 595.749 220.944 596.124 220.277 596.791C219.61 597.458 219.235 598.362 219.235 599.305C219.235 600.249 219.61 601.153 220.277 601.82C220.944 602.487 221.848 602.862 222.792 602.862H294.936Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
                <motion.path id="Vector_23" d="M294.936 581.908C295.403 581.909 295.867 581.818 296.299 581.639C296.731 581.461 297.124 581.199 297.455 580.869C297.785 580.538 298.048 580.146 298.227 579.714C298.406 579.282 298.498 578.819 298.498 578.352C298.498 577.884 298.406 577.421 298.227 576.99C298.048 576.558 297.785 576.165 297.455 575.835C297.124 575.505 296.731 575.243 296.299 575.064C295.867 574.886 295.403 574.795 294.936 574.795H222.792C221.848 574.795 220.944 575.17 220.277 575.837C219.61 576.504 219.235 577.409 219.235 578.352C219.235 579.295 219.61 580.2 220.277 580.867C220.944 581.534 221.848 581.908 222.792 581.908H294.936Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
                <motion.path id="Vector_24" d="M258.864 515.453C263.291 515.453 267.619 516.766 271.301 519.226C274.982 521.685 277.851 525.182 279.546 529.272C281.24 533.363 281.683 537.864 280.819 542.206C279.956 546.549 277.824 550.537 274.693 553.668C271.562 556.799 267.573 558.931 263.231 559.795C258.888 560.658 254.387 560.215 250.297 558.521C246.206 556.826 242.71 553.957 240.25 550.276C237.79 546.594 236.478 542.266 236.478 537.839C236.484 531.904 238.845 526.214 243.042 522.017C247.238 517.82 252.928 515.46 258.864 515.453ZM258.864 558.129C262.877 558.129 266.8 556.939 270.136 554.71C273.473 552.48 276.074 549.311 277.61 545.604C279.145 541.896 279.547 537.816 278.764 533.88C277.982 529.944 276.049 526.329 273.211 523.491C270.374 520.654 266.758 518.721 262.822 517.938C258.886 517.155 254.806 517.557 251.099 519.093C247.391 520.628 244.222 523.229 241.993 526.566C239.763 529.903 238.573 533.826 238.573 537.839C238.579 543.218 240.719 548.376 244.523 552.179C248.327 555.983 253.484 558.123 258.864 558.129Z"
                    // fill="#E6E6E6" 
                    stroke="#E6E6E6"
                    variants={pathVariants}
                    custom={"#E6E6E6"}
                />
            </motion.g>
        </motion.svg>

    )
}

export default SvgMobileApp
