import React from 'react'
import { motion } from 'framer-motion'

const svgVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.25
        }
    }
}

const groupVariants = {
    hidden: {
        scale: 0.5,
        opacity: 0
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            duration: 1,
            type: 'spring'
        }
    }
}

const SvgFuturisticInterface = () => {
    return (
        <motion.svg viewBox="0 0 897 698" fill="none" xmlns="http://www.w3.org/2000/svg"
            variants={svgVariants}
            initial="hidden"
            animate="visible"
            exit={{
                opacity: 0, transition: {
                    duration: 0.2
                }
            }}
        >
            <motion.g id="props_1"
                variants={groupVariants}
            >
                <path id="Vector" opacity="0.4" d="M369 646L706.75 0H31.25L369 646L706.75 0H31.25L369 646Z" fill="url(#paint0_linear_2_2)" />
                <path id="Vector_2" d="M415 571H323V663H415V571Z" fill="#3F3D56" />
                <path id="Vector_3" d="M897 662H0V664H897V662Z" fill="#2F2E41" />
                <path id="Vector_4" d="M689.863 471.764C723.855 471.764 751.411 464.792 751.411 456.192C751.411 447.591 723.855 440.619 689.863 440.619C655.872 440.619 628.316 447.591 628.316 456.192C628.316 464.792 655.872 471.764 689.863 471.764Z" fill="#2F2E41" />
                <path id="Vector_5" d="M727.305 464.621L725.835 464.818L752.53 663.921L754 663.724L727.305 464.621Z" fill="#2F2E41" />
                <path id="Vector_6" d="M702.837 467.621L701.36 467.75L718.416 664.257L719.893 664.129L702.837 467.621Z" fill="#2F2E41" />
                <path id="Vector_7" d="M654.058 460.131L635.491 663.767L636.819 663.888L655.386 460.252L654.058 460.131Z" fill="#2F2E41" />
                <path id="Vector_8" d="M676.005 456.459L674.671 456.507L682.152 664.588L683.486 664.54L676.005 456.459Z" fill="#2F2E41" />
            </motion.g>
            <motion.g id="man_2"
                variants={groupVariants}
            >
                <path id="Vector_9" d="M665.763 248.932C665.763 248.932 665.022 278.593 659.09 282.301C653.157 286.009 669.471 297.132 669.471 297.132L693.942 292.682L709.514 273.402C709.514 273.402 698.391 260.055 702.099 248.932H665.763Z" fill="#FFB6B6" />
                <path id="Vector_10" opacity="0.1" d="M665.763 248.932C665.763 248.932 665.022 278.593 659.09 282.301C653.157 286.009 669.471 297.132 669.471 297.132L693.942 292.682L709.514 273.402C709.514 273.402 698.391 260.055 702.099 248.932H665.763Z" fill="black" />
                <path id="Vector_11" d="M632.394 277.852C632.394 277.852 619.788 283.042 619.046 300.839C618.305 318.636 619.046 370.544 619.046 370.544L640.551 369.061L632.394 277.852Z" fill="#F0F0F0" />
                <path id="Vector_12" d="M611.631 403.172C611.631 403.172 526.354 409.104 540.443 437.282C554.533 465.461 622.754 512.919 622.754 512.919C622.754 512.919 635.36 515.886 637.585 516.627C639.81 517.369 673.92 494.381 673.92 494.381C673.92 494.381 653.157 481.775 643.517 479.55C633.877 477.326 606.44 453.596 606.44 453.596L683.56 434.316L638.327 395.756L611.631 403.172Z" fill="#2F2E41" />
                <path id="Vector_13" d="M679.853 421.71C679.853 421.71 630.911 490.943 640.551 518.245C650.191 545.547 643.517 636.015 643.517 636.015C643.517 636.015 679.853 647.879 679.853 636.015C679.853 624.15 690.234 540.356 679.853 512.178C679.853 512.178 682.077 494.381 688.01 491.415C693.942 488.449 714.705 461.012 714.705 461.012C714.705 461.012 736.951 450.63 733.985 440.99C731.019 431.35 679.853 421.71 679.853 421.71Z" fill="#2F2E41" />
                <path id="Vector_14" d="M713.963 233.359C713.963 239.226 712.224 244.961 708.965 249.838C705.705 254.716 701.073 258.518 695.653 260.763C690.233 263.008 684.269 263.595 678.515 262.451C672.761 261.306 667.476 258.481 663.328 254.333C659.18 250.185 656.355 244.9 655.21 239.146C654.066 233.392 654.653 227.428 656.898 222.008C659.143 216.588 662.945 211.956 667.823 208.697C672.701 205.437 678.435 203.698 684.302 203.698C688.198 203.696 692.055 204.462 695.655 205.952C699.254 207.442 702.525 209.627 705.28 212.382C708.034 215.136 710.219 218.407 711.709 222.006C713.199 225.606 713.965 229.464 713.963 233.359V233.359Z" fill="#FFB6B6" />
                <path id="Vector_15" d="M703.582 263.021C703.582 263.021 748.074 281.559 741.4 305.289C734.726 329.018 720.637 431.35 699.133 431.35C677.628 431.35 632.394 419.486 629.428 415.036C626.462 410.587 640.551 320.119 640.551 320.119C640.551 320.119 657.589 264.961 663.901 269.552C663.901 269.552 659.831 293.424 681.336 287.492C702.84 281.559 703.582 263.021 703.582 263.021Z" fill="#F0F0F0" />
                <path id="Vector_16" d="M664.787 263.021C664.787 263.021 627.203 268.212 628.686 276.369L625.72 307.513L637.585 370.544L636.102 391.307L599.767 403.913L642.776 416.519C642.776 416.519 660.573 331.242 657.607 314.929C654.64 298.615 664.787 263.021 664.787 263.021Z" fill="#3F3D56" />
                <path id="Vector_17" d="M702.43 260.394C702.43 260.394 742.883 283.784 745.85 291.199C748.816 298.615 745.85 369.802 745.85 369.802C745.85 369.802 751.04 436.541 736.21 453.596C736.21 453.596 741.4 444.698 713.963 444.698C686.527 444.698 673.179 439.507 672.437 438.766C671.696 438.024 657.607 438.766 657.607 438.766C657.607 438.766 652.416 332.726 669.471 313.446C686.527 294.166 704.986 271.857 702.43 260.394Z" fill="#3F3D56" />
                <path id="Vector_18" d="M623.496 416.519V425.418C623.496 425.418 593.834 452.855 605.699 460.27C617.563 467.685 640.551 433.575 640.551 433.575L643.345 413.754L623.496 416.519Z" fill="#FFB6B6" />
                <path id="Vector_19" opacity="0.1" d="M623.496 416.519V425.418C623.496 425.418 593.834 452.855 605.699 460.27C617.563 467.685 640.551 433.575 640.551 433.575L643.345 413.754L623.496 416.519Z" fill="black" />
                <path id="Vector_20" d="M635.36 510.695L643.517 522.559C643.517 522.559 615.339 565.569 627.945 570.018C640.551 574.467 695.425 521.076 694.683 516.627C693.942 512.178 667.988 494.381 667.988 494.381L635.36 510.695Z" fill="#2F2E41" />
                <path id="Vector_21" d="M645.742 630.082L639.068 659.744C639.068 659.744 613.114 693.855 624.979 696.821C636.843 699.787 663.539 696.079 667.247 687.922C670.954 679.766 675.403 668.642 681.336 659.744C687.268 650.845 676.145 630.082 676.145 630.082H645.742Z" fill="#2F2E41" />
                <path id="Vector_22" d="M624.237 357.196L619.047 370.544L621.271 421.71C621.271 421.71 639.81 414.295 645.742 421.71L640.551 370.544L632.394 357.196H624.237Z" fill="#F0F0F0" />
                <path id="Vector_23" d="M651.674 421.71L637.585 426.159C637.585 426.159 582.711 443.956 607.182 456.562C631.653 469.169 645.742 448.405 645.742 448.405L655.382 440.99L651.674 421.71Z" fill="#FFB6B6" />
                <path id="Vector_24" d="M734.727 293.424C734.727 293.424 714.705 303.064 711.739 329.018C708.773 354.972 705.065 389.082 705.065 389.082L667.988 413.553C667.988 413.553 642.632 412.547 646.412 421.949C650.191 431.35 650.933 451.372 657.607 448.405C664.28 445.439 673.92 427.642 683.56 426.901C693.2 426.159 733.243 410.587 737.693 392.79C742.142 374.993 759.197 314.929 751.782 306.03C744.367 297.132 740.659 289.716 734.727 293.424Z" fill="#F0F0F0" />
                <path id="Vector_25" d="M656.23 214.452C656.23 214.452 644.362 214.272 641.775 218.856C639.188 223.44 640.037 226.764 643.545 226.847C647.053 226.931 663.388 217.307 663.388 217.307L656.23 214.452Z" fill="#3F3D56" />
                <path id="Vector_26" opacity="0.1" d="M656.23 214.452C656.23 214.452 644.362 214.272 641.775 218.856C639.188 223.44 640.037 226.764 643.545 226.847C647.053 226.931 663.388 217.307 663.388 217.307L656.23 214.452Z" fill="black" />
                <path id="Vector_27" d="M708.105 229.627L699.988 236.66C699.988 236.66 692.281 243.806 681.188 232.805C670.094 221.803 654.404 213.066 647.941 215.7L664.418 208.763L680.43 208.319L698.623 215.98L708.105 229.627Z" fill="#3F3D56" />
                <path id="Vector_28" d="M713.646 237.554C710.49 233.989 707.086 230.652 703.46 227.566C696.464 221.56 686.573 214.62 676.053 212.19C676.053 212.19 665.5 208.841 657.99 212.068L672.512 196.207C672.512 196.207 680.361 191.748 684.464 192.879C684.464 192.879 698.265 195.847 702.169 199.315C702.279 199.411 702.38 199.51 702.473 199.606C704.855 199.948 707.036 201.13 708.624 202.938C712.177 206.998 717.767 214.955 716.555 223.79C714.805 236.549 713.646 237.554 713.646 237.554Z" fill="#3F3D56" />
                <path id="Vector_29" opacity="0.1" d="M706.182 231.447C676.287 206.257 659.295 211.735 659.127 211.794L658.636 210.394C659.35 210.142 676.503 204.499 707.138 230.313L706.182 231.447Z" fill="black" />
                <path id="Vector_30" d="M705.581 201.102C706.4 199.684 705.339 197.538 703.211 196.309C701.083 195.08 698.694 195.234 697.875 196.653C697.056 198.072 698.117 200.218 700.245 201.447C702.373 202.675 704.762 202.521 705.581 201.102Z" fill="#3F3D56" />
                <path id="Vector_31" d="M709.679 237.399C710.895 237.221 711.641 235.435 711.345 233.408C711.049 231.382 709.823 229.884 708.607 230.061C707.392 230.239 706.646 232.025 706.942 234.052C707.238 236.078 708.464 237.576 709.679 237.399Z" fill="#FFB6B6" />
                <path id="Vector_32" d="M683.79 253.766C686.63 249.021 682.255 246.981 682.255 246.981C682.255 246.981 672.245 243.333 668.522 241.969C664.792 240.612 663.057 245.98 663.057 245.98C662.382 246.299 658.318 243.2 655.464 240.322C656.561 244.86 658.713 249.074 661.745 252.623C664.778 256.172 668.605 258.956 672.915 260.748C677.226 262.54 681.898 263.289 686.553 262.936C691.208 262.582 695.713 261.135 699.704 258.713L704.954 246.025C697.708 250.858 690.647 253.509 683.79 253.766ZM679.245 255.554C678.353 256.443 677.247 257.087 676.034 257.424C674.821 257.76 673.541 257.778 672.319 257.474L672.549 256.228C672.549 256.228 676.849 256.013 675.033 254.908C673.019 253.94 670.916 253.173 668.752 252.617C668.752 252.617 667.38 252.12 668.767 254.404L669.538 255.353L668.982 256.258C668.982 256.258 664.948 253.9 665.326 249.814C665.326 249.814 664.903 246.099 669.805 247.886C674.714 249.681 678.251 250.963 678.251 250.963C678.251 250.963 682.366 252.469 679.245 255.554V255.554Z" fill="#3F3D56" />
            </motion.g>
            <motion.g id="holo1_3"
                variants={groupVariants}
            >
                <path id="Vector_33" d="M374 368C278.056 368 200 289.944 200 194C200 98.0561 278.056 20 374 20C469.944 20 548 98.0561 548 194C548 289.944 469.944 368 374 368ZM374 25C280.813 25 205 100.813 205 194C205 287.187 280.813 363 374 363C467.187 363 543 287.187 543 194C543 100.813 467.187 25 374 25V25Z" fill="#3F3D56" />
            </motion.g>
            <motion.g id="holo2_4"
                variants={groupVariants}
            >
                <path id="Vector_34" d="M374 326C301.215 326 242 266.785 242 194C242 121.215 301.215 62 374 62C446.785 62 506 121.215 506 194C506 266.785 446.785 326 374 326ZM374 65C348.486 65 323.545 72.5657 302.331 86.7404C281.118 100.915 264.583 121.062 254.82 144.634C245.056 168.206 242.501 194.143 247.479 219.167C252.456 244.19 264.742 267.176 282.783 285.217C300.824 303.258 323.81 315.544 348.833 320.521C373.857 325.499 399.794 322.944 423.366 313.18C446.938 303.417 467.085 286.883 481.26 265.669C495.434 244.455 503 219.514 503 194C502.961 159.799 489.358 127.01 465.174 102.826C440.99 78.6421 408.201 65.0387 374 65Z" fill="#3F3D56" />
            </motion.g>
            <motion.g id="holo3_5"
                variants={groupVariants}
            >
                <path id="Vector_35" d="M374 236C365.693 236 357.573 233.537 350.666 228.922C343.759 224.307 338.376 217.747 335.197 210.073C332.018 202.398 331.186 193.953 332.807 185.806C334.428 177.659 338.428 170.175 344.302 164.302C350.175 158.428 357.659 154.428 365.806 152.807C373.953 151.186 382.398 152.018 390.073 155.197C397.747 158.376 404.307 163.759 408.922 170.666C413.537 177.573 416 185.693 416 194C415.987 205.135 411.558 215.811 403.685 223.685C395.811 231.558 385.135 235.987 374 236ZM374 154C366.089 154 358.355 156.346 351.777 160.741C345.199 165.136 340.072 171.384 337.045 178.693C334.017 186.002 333.225 194.044 334.769 201.804C336.312 209.563 340.122 216.69 345.716 222.284C351.31 227.878 358.437 231.688 366.196 233.231C373.956 234.775 381.998 233.983 389.307 230.955C396.616 227.928 402.864 222.801 407.259 216.223C411.654 209.645 414 201.911 414 194C413.988 183.395 409.77 173.228 402.271 165.729C394.772 158.23 384.605 154.012 374 154V154Z" fill="#3F3D56" />
                <path id="Vector_36" d="M364 144C358.616 144 353.284 145.06 348.31 147.121C343.336 149.181 338.816 152.201 335.009 156.009C331.201 159.816 328.181 164.336 326.121 169.31C324.06 174.284 323 179.616 323 185H364V144Z" fill="#FF8C00" />
                <path id="Vector_37" opacity="0.3" d="M323 203C323 213.874 327.32 224.302 335.009 231.991C342.698 239.68 353.126 244 364 244V203H323Z" fill="#FF8C00" />
                <path id="Vector_38" opacity="0.3" d="M381 144V185H422C422 179.616 420.94 174.284 418.879 169.31C416.819 164.336 413.799 159.816 409.991 156.009C406.184 152.201 401.664 149.181 396.69 147.121C391.716 145.06 386.384 144 381 144V144Z" fill="#FF8C00" />
                <path id="Vector_39" d="M381 240C391.874 240 402.302 235.68 409.991 227.991C417.68 220.302 422 209.874 422 199H381V240Z" fill="#FF8C00" />
            </motion.g>
            <motion.g id="holo4_6"
                variants={groupVariants}
            >
                <path id="Vector_40" d="M415.406 144.923L413.594 144.077L434.863 98.5H572.5V100.5H436.137L415.406 144.923Z" fill="#3F3D56" />
                <path id="Vector_41" d="M606 55H528V61H606V55Z" fill="#3F3D56" />
                <path id="Vector_42" d="M606 68H528V74H606V68Z" fill="#3F3D56" />
                <path id="Vector_43" d="M606 81H528V87H606V81Z" fill="#3F3D56" />
            </motion.g>
            <motion.g id="holo5_7"
                variants={groupVariants}
            >
                <path id="Vector_44" d="M413 385H335V391H413V385Z" fill="#3F3D56" />
                <path id="Vector_45" d="M413 398H335V404H413V398Z" fill="#3F3D56" />
                <path id="Vector_46" d="M413 411H335V417H413V411Z" fill="#3F3D56" />
                <path id="Vector_47" d="M446 428H444V272.286L421.15 235.528L422.85 234.472L446 271.714V428Z" fill="#3F3D56" />
                <path id="Vector_48" d="M304 428H302V271.714L325.151 234.472L326.849 235.528L304 272.286V428Z" fill="#3F3D56" />
            </motion.g>
            <defs>
                <linearGradient id="paint0_linear_2_2" x1="369" y1="646" x2="369" y2="0" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E4E4E4" />
                    <stop offset="0.4735" stopColor="#F0F0F0" stopOpacity="0.7" />
                </linearGradient>
                <clipPath id="clip0_2_2">
                    <rect width="897" height="697.835" fill="white" />
                </clipPath>
            </defs>
        </motion.svg>

    )
}

export default SvgFuturisticInterface
