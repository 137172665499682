import React from 'react'
import { motion } from 'framer-motion'

const svgVariants = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.75
        }
    }
}

const pathVariants = {
    hidden: {
        fill: "rgba(255, 255, 255, 0)",
        pathLength: 0,
    },
    visible: {
        fill: "rgba(255, 255, 255, 1)",
        pathLength: 1,
        transition: {
            duration: 1.5,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut"
        }
    }
}

const SvgVisionaryTechnology = () => {
    return (
        <motion.svg width="364" height="728" viewBox="0 0 364 728" fill="none" xmlns="http://www.w3.org/2000/svg"
            variants={svgVariants}
            initial="hidden"
            animate="visible"
            exit={{
                opacity: 0, transition: {
                    duration: 0.2
                }
            }}
        >
            <path id="Vector" d="M363.682 172.947H359.683V63.4019C359.683 46.5867 353.004 30.4602 341.113 18.57C329.223 6.67988 313.097 4.37786e-05 296.282 3.05178e-05H64.1949C55.8688 1.08195e-05 47.6243 1.63993 39.932 4.82616C32.2398 8.01238 25.2504 12.6825 19.3629 18.5699C13.4755 24.4573 8.80537 31.4467 5.61911 39.1389C2.43285 46.8312 0.792894 55.0757 0.792877 63.4018V664.376C0.792864 681.191 7.47268 697.318 19.3628 709.208C31.253 721.098 47.3795 727.778 64.1947 727.778H296.281C313.097 727.778 329.223 721.098 341.113 709.208C353.003 697.318 359.683 681.192 359.683 664.376V250.924H363.682V172.947Z" fill="#3F3D56" />
            <path id="Vector_2" d="M298.84 16.495H268.545C269.938 19.9107 270.47 23.6165 270.093 27.2861C269.717 30.9557 268.444 34.4764 266.386 37.5381C264.329 40.5997 261.55 43.1084 258.294 44.8431C255.039 46.5778 251.406 47.4853 247.717 47.4855H114.758C111.069 47.4853 107.437 46.5778 104.182 44.8431C100.926 43.1084 98.147 40.5997 96.0894 37.5381C94.0318 34.4764 92.7588 30.9557 92.3824 27.2861C92.0059 23.6165 92.5378 19.9106 93.9309 16.495H65.6353C53.0779 16.495 41.0348 21.4834 32.1554 30.3628C23.2759 39.2422 18.2875 51.2853 18.2874 63.8427V663.935C18.2874 670.153 19.5121 676.31 21.8916 682.054C24.271 687.799 27.7587 693.019 32.1553 697.415C36.552 701.812 41.7715 705.299 47.516 707.679C53.2605 710.058 59.4175 711.283 65.6353 711.283H298.84C305.057 711.283 311.214 710.058 316.959 707.679C322.703 705.3 327.923 701.812 332.32 697.415C336.716 693.019 340.204 687.799 342.583 682.055C344.963 676.31 346.187 670.153 346.187 663.935V63.8427C346.187 57.6249 344.963 51.468 342.583 45.7235C340.204 39.979 336.716 34.7594 332.32 30.3628C327.923 25.9662 322.703 22.4786 316.959 20.0991C311.214 17.7197 305.057 16.495 298.84 16.495Z" fill="white" />
            <path id="Vector_3" d="M55.2005 89.9715C53.8749 89.9731 52.604 90.5004 51.6667 91.4377C50.7293 92.3751 50.202 93.6459 50.2005 94.9715V644.807C50.202 646.133 50.7293 647.403 51.6667 648.341C52.604 649.278 53.8749 649.805 55.2005 649.807H309.275C310.6 649.805 311.871 649.278 312.809 648.341C313.746 647.403 314.273 646.133 314.275 644.807V94.9715C314.273 93.6459 313.746 92.3751 312.809 91.4377C311.871 90.5004 310.6 89.9731 309.275 89.9715H55.2005Z" fill="#FF8C00" />
            <motion.path id="line6" d="M156.837 468.675C156.837 429.76 164.247 387.054 189.252 353.714C224.065 307.295 222.208 250.106 214.517 210.304C209.366 183.867 200.818 158.206 189.087 133.96L192.449 132.207C204.333 156.749 212.995 182.724 218.22 209.486C226.075 250.07 227.952 308.431 192.284 355.989C125.465 445.082 185.478 604.613 186.09 606.213L182.551 607.571C171.887 577.655 164.355 546.714 160.074 515.243C157.969 499.809 156.887 484.252 156.837 468.675Z"
                // fill="white"
                stroke="white"
                variants={pathVariants}
            />
            <motion.path id="line5" opacity="0.3" d="M190.768 133.065C190.768 133.065 222.448 263.862 179.072 354.851C135.697 445.841 179.394 606.968 179.394 606.968"
                // fill="white" 
                stroke="white"
                variants={pathVariants}
            />
            <motion.path id="line4" opacity="0.3" d="M141.556 256.547C141.627 240.747 143.2 224.989 146.255 209.486C151.481 182.724 160.143 156.749 172.027 132.207L175.388 133.96C163.657 158.206 155.11 183.867 149.959 210.304C142.269 250.104 140.41 307.294 175.224 353.714C210.861 401.23 210.761 467.787 204.401 515.244C200.121 546.714 192.588 577.655 181.925 607.571L178.385 606.213C178.999 604.611 239.014 445.084 172.191 355.989C148.801 324.802 141.556 288.966 141.556 256.547Z"
                // fill="white" 
                stroke="white"
                variants={pathVariants}
            />
            <motion.path id="line3" opacity="0.3" d="M156.821 257.191C156.821 191.538 180.374 133.236 180.734 132.361L184.241 133.805C183.706 135.103 131.354 264.77 184.118 353.886C237.802 444.543 189.883 605.824 189.392 607.442L185.764 606.342C186.249 604.741 233.688 445.039 180.857 355.817C162.827 325.369 156.821 290.341 156.821 257.191Z"
                // fill="white" 
                stroke="white"
                variants={pathVariants}
            />
            <motion.path id="line2" opacity="0.3" d="M185.403 133.084C185.403 133.084 142.027 263.862 185.403 354.851C228.779 445.841 189.501 606.892 189.501 606.892"
                // fill="white" 
                stroke="white"
                variants={pathVariants}
            />
            <motion.path id="line1" opacity="0.3" d="M190.768 133.084C190.768 133.084 259.01 263.862 190.768 354.851C122.526 445.841 184.32 606.892 184.32 606.892"
                // fill="white"
                stroke="white"
                variants={pathVariants}
            />
        </motion.svg>
    )
}

export default SvgVisionaryTechnology
