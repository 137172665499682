import React, { useState, useContext, useEffect } from "react";
import tw, { styled, css } from "twin.macro";
import { Link } from "gatsby";
import { AnimatePresence } from "framer-motion";

import { EmailContext } from "../contexts/EmailContext";

import Button from "./Button";

const Wrapper = styled.div`
  ${tw`
        w-full
    `}
`;

const MainContainer = styled.div`
  ${tw`
        mt-12 mb-20
        relative
    `}
`;

const MainContent = styled.div`
  ${tw`
        flex justify-between items-center 
        flex-col xl:flex-row
    `}
`;

const MainText = styled.div`
  ${tw`
        relative 
        // xl:mr-28
        text-center xl:text-left
    `}

  max-width: 600px;
`;

const Heading = styled.div`
  ${tw`
        font-bold mb-6
    `}
`;

const AnimatedLineContainer = styled.div`
  ${tw`
        overflow-y-hidden
    `}
`;

// This takes on the size of an h2 as per the custom global styles but every page needs to have an h1 for SEO optimization
const RevolvingHeader = styled.h1`
  ${tw`
        transition-transform duration-1000

        text-5xl sm:text-6xl
        leading-tight sm:leading-tight
    `}

  transform: translateY(0);

  ${({ hide }) =>
    hide &&
    css`
      transform: translateY(100%);
    `}
`;

const Body = styled.p`
  ${tw`
        text-xl mb-6 xl:mr-12
    `}
`;

const ButtonsContainer = styled.ul`
  ${tw`
        items-center
        flex flex-wrap
        list-none
        justify-center xl:justify-start
    `}

  > li {
    ${tw`
            text-lg mb-4
            mx-2 xl:mr-4 xl:ml-0
        `}
  }
`;

const ModifiedLink = styled(Link)`
  ${tw`
        underline
    `}

  &:hover {
    text-decoration-color: darkorange;
  }
`;

const MainImgs = styled.div`
  ${tw`
        relative rounded bg-white block
        mt-12 xl:mt-0
        w-full h-full
        flex justify-center items-center
    `}

  max-width: 600px;

  @media (max-width: 1280px) {
    height: 380px;
  }

  > svg {
    ${tw`
            w-full h-auto
            absolute
        `}
  }
`;

const LogosContainer = styled.div`
  ${tw`
        mt-0 
        relative
    `}
`;

const LogosHeader = styled.div`
  ${tw`
        font-medium uppercase text-center
        flex justify-center relative
        mb-4 py-0 px-4
        text-lg
    `}
`;

const LogosBody = styled.div`
  ${tw`
        flex flex-wrap
        items-center justify-center
    `}
`;

const LogoLink = styled(Link)`
  ${tw`
        mb-6 xl:mb-0 mx-8
    `}

  img, svg {
    ${tw`
            h-20 w-auto
            filter grayscale 
            hover:grayscale-0 
            hover:transform hover:scale-110
            transition-all duration-300
        `}
  }
`;

const DynamicHero = ({ children, data: { title, body, logos } }) => {
  const { setShowEmail, setType } = useContext(EmailContext);

  const [firstLineStr, setFirstLineStr] = useState(title.upperLines[0]);
  const [secondLineStr, setSecondLineStr] = useState(title.middleLines[0]);

  const [linesHidden, setLinesHidden] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const hideAndReveal = () => {
      setLinesHidden(true);
      let tempCounter = counter + 1;
      setCounter(tempCounter);

      setTimeout(() => {
        setFirstLineStr(
          title.upperLines[tempCounter % title.upperLines.length]
        );
        setSecondLineStr(
          title.middleLines[tempCounter % title.middleLines.length]
        );
        setLinesHidden(false);
      }, 1000);
    };

    const interval = setInterval(() => {
      hideAndReveal();
    }, 3000);

    return () => clearInterval(interval);
  }, [counter]);

  function renderImage() {
    if (Array.isArray(children)) return children[counter % children.length];
    return children;
  }

  return (
    <Wrapper>
      <MainContainer>
        <MainContent>
          <MainText>
            <Heading>
              <RevolvingHeader>{firstLineStr}</RevolvingHeader>
              <AnimatedLineContainer>
                <RevolvingHeader hide={linesHidden}>
                  {secondLineStr}
                </RevolvingHeader>
              </AnimatedLineContainer>
              <RevolvingHeader>{title.staticLine}</RevolvingHeader>
            </Heading>
            <Body>{body}</Body>
            <ButtonsContainer>
              <li>
                <Button
                  handleClick={() => {
                    setShowEmail(true);
                    setType("general");
                  }}
                >
                  Let's Chat!
                </Button>
              </li>
              <li>
                <ModifiedLink to="/projects">
                  Need more info? Check our work!
                </ModifiedLink>
              </li>
            </ButtonsContainer>
          </MainText>
          <MainImgs>
            {children && <AnimatePresence>{renderImage()}</AnimatePresence>}
          </MainImgs>
        </MainContent>
      </MainContainer>
      {logos && (
        <LogosContainer>
          <LogosHeader>{logos.header}</LogosHeader>
          <LogosBody>
            {logos.images.map(({ image, to, imgTitle }, imageIdx) => (
              <LogoLink to={to}>
                <img
                  src={
                    image.relativePath ? `/img/${image.relativePath}` : image
                  }
                  alt={`${imgTitle} logo`}
                  title={imgTitle}
                  key={imageIdx}
                />
              </LogoLink>
            ))}
          </LogosBody>
        </LogosContainer>
      )}
    </Wrapper>
  );
};

export default DynamicHero;
