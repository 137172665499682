import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import tw, { styled } from "twin.macro";
import { createHTML, applyCustomMdEmphasis } from "../utils";

import DynamicHero from "../components/DynamicHero";
import Section from "../components/Section";
import Button from "../components/Button";
import ImageCards from "../components/ImageCards";
import ContactUs from "../components/ContactUs";

import SvgLatestDevelopment from "../../static/img/svg/real-time-sync.svg";
import SvgMobileApp from "../components/SvgMobileApp";
import SvgVisionaryTechnology from "../components/SvgVisionaryTechnology";
import SvgFuturisticInterface from "../components/SvgFuturisticInterface";
import SvgFirmware from "../components/SvgFirmware";
import SvgMakerLaunch from "../components/SvgMakerLaunch";

const LatDevContainer = styled.div`
  ${tw`
    py-28
    relative overflow-hidden
  `}
`;

const LatDevContents = styled.div`
  ${tw`
    grid lg:grid-cols-[2fr 1fr] gap-12
  `}
`;

const LatDevHeader = styled.h4`
  ${tw`
    font-bold mb-12
    text-center sm:text-left
  `}
`;

const LatDevGroupOuter = styled.div`
  ${tw`
    grid grid-cols-1 sm:grid-cols-2 
    gap-x-6 gap-y-12
  `}
`;

const LatDevGroupInner = styled.div`
  ${tw`
    flex flex-col
    items-center sm:items-start
    text-center sm:text-left
  `}
`;

const LatDevBody = styled.div`
  ${tw`
    text-lg mb-6 flex-grow
  `}
`;

const LatDevImageContainer = styled.div`
  ${tw`
    items-center 
    hidden lg:flex 
    justify-end relative
  `}

  > svg, img {
    ${tw`
      w-auto
    `}

    height: 300px;
  }
`;

const LatDevButton = styled(Button)`
  background-color: #3f3d56;

  :hover {
    background-color: #6d6a95;
  }
`;

const ReasonsPreHeader = styled.div`
  ${tw`
    font-medium uppercase text-center text-lg
    flex justify-center relative
    mb-4 px-4
  `}
`;

const SectionHeader = styled.h2`
  ${tw`
    mb-7
    text-center font-bold
    relative
  `}
`;

const SectionSubheader = styled.div`
  ${tw`
    text-center text-xl
    mx-auto mb-14
  `}

  @media (min-width: 1024px) {
    grid-column: narrow;
  }
`;

const ReasonsContent = styled.div`
  ${tw`
    grid
  `}

  grid-template-columns: minmax(1.5em,1fr) minmax(5em,17em) minmax(5em,17em) minmax(1.5em,1fr);

  @media (min-width: 1280px) {
    grid-template-columns:
      [full-start] minmax(2rem, 1fr) [illu-start] minmax(auto, 45rem)
      minmax(2rem, 15rem) minmax(2rem, 15rem) minmax(auto, 45rem) [illu-end] minmax(
        2rem,
        1fr
      )
      [full-end];
  }

  grid-column: full;
`;

const ReasonsBuild = styled.div`
  ${tw`
    flex flex-col
    text-center 
    px-6 xl:px-12
    mt-14

    col-span-full
    md:col-start-1 md:col-end-3

    row-start-3 row-end-auto 
    xl:row-start-1
  `}

  @media (min-width: 1280px) {
    grid-column: illu-start;
  }
`;

const ReasonsSell = styled(ReasonsBuild)`
  ${tw`
    md:col-start-3 md:col-end-5
    xl:col-start-5 xl:col-end-auto

    row-start-4
    md:row-start-3 
    xl:row-start-1
  `}
`;

const ReasonsAtg = styled.div`
  ${tw`
    flex flex-col
    text-center 
    px-6
    mt-14

    col-span-full
    row-start-5 row-end-auto
    md:row-start-4
  `}

  @media (min-width: 1280px) {
    grid-column: illu;
  }
`;

const ReasonsContentHeader = styled.h4`
  ${tw`
    font-bold
    mb-3
  `}
`;

const ReasonsContentSubHeader = styled.div`
  ${tw`
    font-bold text-xl
    mb-3
  `}
`;

const ReasonsContentBody = styled.div`
  ${tw`
    text-lg mb-6
  `}
`;

const ReasonsButtonLink = styled(Link)`
  ${tw`
    w-max
    mx-auto
  `}
`;

const ReasonsImage = styled.div`
  ${tw`
    p-1
    row-start-2 row-end-auto
    xl:row-start-1 xl:row-end-3
    col-end-auto
  `}

  &:nth-child(2) {
    ${tw`
      col-start-2
      xl:col-start-3 
    `}
  }

  &:nth-child(3) {
    ${tw`
      mt-12
      col-start-3
      xl:col-start-4
    `}
  }

  > svg {
    ${tw`
      h-auto
      w-full
    `}
  }
`;

const LatDevSection = styled(Section)`
  background-color: orange;
  background-image: url(/img/svg/backgrounds/sun-tornado.svg);
  background-size: cover;
`;

const IndexPage = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, hero, latestDevelopment, reasons, industries },
    },
  },
}) => {
  return (
    <Layout title={seo.title} description={seo.description} isHomePage>
      <Section>
        <DynamicHero data={hero}>
          <SvgFuturisticInterface key="futuristicInterface" />
          <SvgFirmware key="firmware" />
          <SvgMakerLaunch key="makerLaunch" />
        </DynamicHero>
      </Section>

      <LatDevSection>
        <LatDevContainer>
          <LatDevContents>
            <div>
              <LatDevHeader>{latestDevelopment.title}</LatDevHeader>
              {latestDevelopment.bodyList && (
                <LatDevGroupOuter>
                  {latestDevelopment.bodyList.map(
                    ({ text, button: { label, url } }, idx) => (
                      <LatDevGroupInner>
                        <LatDevBody
                          dangerouslySetInnerHTML={createHTML(text)}
                        />
                        <Link to={url}>
                          <LatDevButton>{label || "Learn More"}</LatDevButton>
                        </Link>
                      </LatDevGroupInner>
                    )
                  )}
                </LatDevGroupOuter>
              )}
            </div>
            <LatDevImageContainer>
              <SvgLatestDevelopment />
            </LatDevImageContainer>
          </LatDevContents>
        </LatDevContainer>
      </LatDevSection>

      <Section>
        <SectionHeader>{reasons.title}</SectionHeader>
        <SectionSubheader
          dangerouslySetInnerHTML={{
            __html: applyCustomMdEmphasis(reasons.subtitle),
          }}
        />

        <ReasonsContent>
          <ReasonsBuild>
            <ReasonsContentHeader>
              {reasons.leftText.title}
            </ReasonsContentHeader>
            <ReasonsContentSubHeader>
              {reasons.leftText.subtitle}
            </ReasonsContentSubHeader>
            <ReasonsContentBody>{reasons.leftText.body}</ReasonsContentBody>
            <ReasonsButtonLink to={reasons.leftText.url}>
              <Button>Learn More</Button>
            </ReasonsButtonLink>
          </ReasonsBuild>

          <ReasonsImage>
            <SvgMobileApp />
          </ReasonsImage>

          <ReasonsImage>
            <SvgVisionaryTechnology />
          </ReasonsImage>

          <ReasonsSell>
            <ReasonsContentHeader>
              {reasons.rightText.title}
            </ReasonsContentHeader>
            <ReasonsContentSubHeader>
              {reasons.rightText.subtitle}
            </ReasonsContentSubHeader>
            <ReasonsContentBody>{reasons.rightText.body}</ReasonsContentBody>
            <ReasonsButtonLink to={reasons.rightText.url}>
              <Button>Learn More</Button>
            </ReasonsButtonLink>
          </ReasonsSell>

          <ReasonsAtg>
            <ReasonsContentHeader>
              {reasons.bottomText.title}
            </ReasonsContentHeader>
            <ReasonsContentSubHeader>
              {reasons.bottomText.subtitle}
            </ReasonsContentSubHeader>
            <ReasonsContentBody
              dangerouslySetInnerHTML={createHTML(reasons.bottomText.body)}
            />
            <ReasonsButtonLink to={reasons.bottomText.url}>
              <Button>Learn More</Button>
            </ReasonsButtonLink>
          </ReasonsAtg>
        </ReasonsContent>
      </Section>

      <Section>
        <SectionHeader>{industries.title}</SectionHeader>
        <SectionSubheader>{industries.subtitle}</SectionSubheader>
        <ImageCards data={industries.cards} />
      </Section>

      <Section>
        <ContactUs />
      </Section>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query indexQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          title {
            upperLines
            middleLines
            staticLine
          }
          body
          logos {
            header
            images {
              image {
                relativePath
              }
              to
              imgTitle
            }
          }
        }
        latestDevelopment {
          title
          bodyList {
            text
            button {
              label
              url
            }
          }
        }
        reasons {
          title
          subtitle
          leftText {
            title
            subtitle
            body
            url
          }
          rightText {
            title
            subtitle
            body
            url
          }
          bottomText {
            title
            subtitle
            body
            url
          }
        }
        industries {
          title
          subtitle
          cards {
            useIcons
            data {
              text
              url
              image {
                relativePath
              }
              bgColor
            }
          }
        }
      }
    }
  }
`;
